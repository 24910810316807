import React from 'react'
import { BarChart } from '@mui/x-charts/BarChart';
import { useState, useEffect } from 'react';
import axios from 'axios';

const ChartBar = ({year}) => {
  const [totalOrder, setTotalOrder] = useState([]);

  useEffect(() => {
    const getOrderByMonth = async (year) => {
      try {
        const response = await axios.get(`https://diligent-surprise-production.up.railway.app/api/orders/total-order/${year}`);
         console.log(response.data);
        setTotalOrder(response.data);
      } catch (error) {
        console.error(error);
      }
    };

    getOrderByMonth(year);
    // const getAnnualRevenue = async () => {
    //   try {
    //     const response = await axios.get(`api/orders/annualRevenue`);
    //     // console.log(response.data);
    //     setRevenue(response.data);
    //   } catch (error) {
    //     console.error(error);
    //   }
    // };
  }, [year]);

  return (
    <BarChart
    xAxis={[{ scaleType: 'band', data: ['1', '2', '3', '4', '5', '6', '7', '8', '9','10','11', '12']}]}
    series={[{ data: totalOrder }]}
    // width={500}
    height={300}
  />
  
  )
}

export default ChartBar