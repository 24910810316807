import React, { useEffect, useState } from 'react'
import AdminLayout from '../AdminLayout/AdminLayout';
import { Button } from 'antd';
import { useNavigate } from "react-router-dom";
import { MdDelete, MdEdit } from "react-icons/md";
import DataTable from 'react-data-table-component';
import { FaRegCheckCircle } from "react-icons/fa";
import { CiCircleMore } from "react-icons/ci";
import { IoMdCloseCircleOutline } from "react-icons/io";
import AdminFooter from '../Footer/AdminFooter';
import { FaEye } from "react-icons/fa";
import axios from 'axios';
import { BiBoltCircle, BiBadgeCheck } from "react-icons/bi";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

import './ListOrder.css';
import TableOrder from './TableOrder';

const ListOrder = () => {
	const router = useNavigate();
	const handelDetail = () => {
		router(`/admin/detail`);
	};
	const handelEditProduct = () => {
		router(`/admin/editProduct`);
	};

	const CustomButton = ({ onClick }) => (
		<div style={{ display: 'flex', justifyContent: 'space-around' }}>

		</div>

	);
	const [orders, setOrders] = useState([]);
	const [loading, setLoading] = useState(true);
	const [wait, setWait] = useState([]);
	const [transported, setTransported] = useState([]);
	const [delivered, setDelivered] = useState([]);
	const [cancel, setCancel] = useState([]);
	const [evaluate, setEvaluate] = useState([]);


	useEffect(() => {

		const fetchOrders = async () => {
			try {
				const response = await axios.get(`https://diligent-surprise-production.up.railway.app/api/orders`);
				const reversedOrder = response.data.reverse();
				setOrders(reversedOrder);
				 // Lọc các đơn hàng có trạng thái đang chờ xác nhận
				 const ordersWithStatusOne = reversedOrder.filter(order => order.status.id === 1);
				 const ordersWithStatusTwo = reversedOrder.filter(order => order.status.id === 2);
				 const ordersWithStatusThree = reversedOrder.filter(order => order.status.id === 3);
				 const ordersWithStatusFour = reversedOrder.filter(order => order.status.id === 4);
				 const ordersWithStatusFive = reversedOrder.filter(order => order.status.id === 5);
				 setWait(ordersWithStatusOne);
				 setTransported(ordersWithStatusTwo);
				 setDelivered(ordersWithStatusThree);
				 setCancel(ordersWithStatusFive);
				 setEvaluate(ordersWithStatusFour);
				setLoading(false);

			} catch (error) {
				console.error('Error fetching orders:', error);
				setLoading(false);

			}
		};

		fetchOrders();
	}, []);


	const columns = [
		{
			name: 'Mã',
			selector: row => row.id,
			sortable: true,
		},
		{
			name: 'thời gian tạo',
			selector: row => row.createTime.slice(0, 10),
			sortable: true,
		},
		{
			name: 'Trạng thái',
			cell: (row) => {
				if (row.status.id === 1) {
					return (
						<span style={{ display: 'flex', alignItems: 'center' }}>
							<CiCircleMore style={{ marginRight: '5px', color: 'orange', fontSize: '22px' }} />
							{row.status.statusName}
						</span>
					);
				} else if (row.status.id === 2) {
					return (
						<span style={{ display: 'flex', alignItems: 'center' }}>
							<BiBoltCircle style={{ marginRight: '5px', color: 'blue', fontSize: '22px' }} />
							{row.status.statusName}
						</span>
					);
				} else if (row.status.id === 3) {
					return (
						<span style={{ display: 'flex', alignItems: 'center' }}>
							<FaRegCheckCircle style={{ marginRight: '5px', color: 'green', fontSize: '20px' }} />
							{row.status.statusName}
						</span>
					);
				}
				else if (row.status.id === 4) {
					return (
						<span style={{ display: 'flex', alignItems: 'center' }}>
							<BiBadgeCheck style={{ marginRight: '5px', color: 'gray', fontSize: '22px' }} />
							{row.status.statusName}
						</span>
					);
				}

				else if (row.status.id === 5) {
					return (
						<span style={{ display: 'flex', alignItems: 'center' }}>
							<IoMdCloseCircleOutline style={{ marginRight: '5px', color: 'red', fontSize: '22px' }} />
							{row.status.statusName}
						</span>
					);
				} else {
					return row.status.statusName;
				}
			},
			sortable: true,
		},
		{
			name: 'khách hàng',
			selector: row => row.userId.userName,
			sortable: true,
		},
		{
			name: 'Tổng giá',
			selector: row => row.totalPrice,
			sortable: true,
		},
		{
			name: 'Chi tiết',
			selector: row => <FaEye style={{ fontSize: '22px', color: 'gray', cursor: 'pointer' }} onClick={handelDetail} />,
			sortable: true,
		},
	];



	return (
		<>
			<AdminLayout >
				<div className="row">
					<div className="col-md-12">


						<div className="card">
							<div className="card-body">
								<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} className='mb-4'>
									<h2>Đơn hàng</h2>
									<div>
										{/* <Button type="primary" className="tag-button" style={{marginRight: '10px'}} onClick={handelNewProduct}>
											+ Thêm sản phẩm
										</Button> */}
										<Button type="primary" className="tag-import">
											Export DPF
										</Button>
									</div>

								</div>
								<div>
									<Tabs>
									<TabList>
										<Tab>Tất cả</Tab>
										<Tab>Chờ xác nhận</Tab>
										<Tab>Đang vận chuyển</Tab>
										<Tab>Đã giao</Tab>
										<Tab>Đã Hủy</Tab>
										<Tab>Đánh giá</Tab>
									</TabList>



									<TabPanel>
										

										<TableOrder orders={orders}
										 loading={loading} />



									</TabPanel>
									<TabPanel>
										
										<TableOrder orders={wait}
										 loading={loading} />
									</TabPanel>
									<TabPanel>
										
										<TableOrder orders={transported}
										 loading={loading} />
									</TabPanel>
									<TabPanel>
									
										<TableOrder orders={delivered}
										 loading={loading} />
									</TabPanel>
									<TabPanel>
										
										<TableOrder orders={cancel}
										 loading={loading} />
									</TabPanel>
									<TabPanel>
										
										<TableOrder orders={evaluate}
										 loading={loading} />
									</TabPanel>
								</Tabs>
	
								</div>
							
							


							</div>
						</div>

					</div>
				</div>
				<AdminFooter />
			</AdminLayout>
		</>
	)
}
export default ListOrder;