import React from 'react'
import logo from '../../Images/LogoFoxBox.png';
import userImg from '../../Images/avatar.jpg';
import './AdminHeader.css';
import { Link } from 'react-router-dom';

const AdminHeader = () => {
    return (
        <div className="header">
            <div className="header-left">
                {/* <a href="index.html" className="logo">
                    <img src={logo} alt="Logo" />
                </a> */}
                <Link to="/">
                    <img src={logo} style={{ width: '150px', height: '80px' }}></img>
                </Link>

            </div>

            <a id="toggle_btn">
                <i className="fe fe-text-align-left"></i>
            </a>

            {/* <div className="top-nav-search">
                <form>
                    <input type="text" className="form-control" placeholder="Search here" />
                    <button className="btn" type="submit"><i className="fa fa-search"></i></button>
                </form>
            </div> */}

            {/* <a className="mobile_btn" id="mobile_btn">
                <i className="fa fa-bars"></i>
            </a> */}
            {/* <ul className="nav user-menu">

                <li className="nav-item dropdown noti-dropdown">
                    <a href="#" className="dropdown-toggle nav-link" data-toggle="dropdown">
                        <i className="fe fe-bell"></i> <span className="badge badge-pill">3</span>
                    </a>
                    <div className="dropdown-menu notifications">
                    </div>
                </li>
              
            </ul> */}

        </div>
    )
}

export default AdminHeader